export enum Event {
  NEW_YEARS_DAY = "New Year's Day",
  CHINESE_NEW_YEAR = "Chinese New Year",
  VALENTINES_DAY = "Valentine's Day",
  SHROVE_TUESDAY = "Shrove Tuesday",
  ST_PATRICKS_DAY = "St. Patrick's Day",
  APRIL_FOOLS = "April Fools' Day",
  GOOD_FRIDAY = "Good Friday",
  EASTER_SUNDAY = "Easter Sunday",
  STAR_WARS_DAY = "Star Wars Day",
  FIRST_DAY_OF_PRIDE_MONTH = "First Day of Pride Month",
  HALLOWEEN = "Halloween",
  FIRST_DAY_OF_HANUKKAH = "First Day of Hanukkah",
  CHRISTMAS_EVE = "Christmas Eve",
  CHRISTMAS = "Christmas",
  NEW_YEARS_EVE = "New Year's Eve",
}
